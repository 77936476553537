<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-06-13 11:00:15
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-13 15:08:46
-->
<template>
  <p @click="handleClick" class="common-icon share-icon">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.845 5.585a1 1 0 1 0-.518 1.932l1.4.375c-1.032.329-2.18.856-3.207 1.637-1.65 1.255-3.02 3.194-3.02 5.971a1 1 0 1 0 2 0c0-2.023.964-3.416 2.23-4.379 1.002-.76 2.173-1.231 3.132-1.474l-.414 1.544a1 1 0 1 0 1.932.518l1.027-3.833a1 1 0 0 0-.698-1.256l-3.864-1.035zM8 8h5a1 1 0 1 0 0-2H8a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-4a1 1 0 1 0-2 0v4H8V8z" fill="#999"/></svg>
    分享
  </p>
</template>

<script setup>
import { defineEmits } from 'vue'
const emit = defineEmits(['handleClick'])
const handleClick = () => {
  emit('handleClick')
}
</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';
.share-icon:hover {
  svg {
    border-radius: 14px;
  }
  & svg:hover {
    background: #d9d9e580;
    path {
      fill: @active-text-color;
      stroke: none;
    }
  }
}
</style>
