/*
 * @Description: 图片预览
 * @Author: heqi
 * @Date: 2022-03-08 09:17:38
 * @LastEditTime: 2022-07-05 13:45:53
 * @LastEditors: heqi
 * @FilePath: \sns_h5d:\hq\mfzj\src\mixin\imagePreview.js
 */
import { onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
/**
 * @监听帖子公告等编辑器内容查看大图
 */
export default function () {
  const store = useStore()
  var src = []

  const getImageSrc = (e) => {
    // 预览图片查看大图
    if (e.target.nodeName === 'IMG') {
      store.commit('setImagePreVisible', true)
      store.commit('forum/setPreviewSrc', e.target.getAttribute('src'))
      src[0] = e.target.getAttribute('src')
    }
  }

  onMounted(() => {
    // 监听点击图片
    document.querySelector('.content').addEventListener('click', getImageSrc)
  })
  onBeforeUnmount(() => {
    document.querySelector('.content').removeEventListener('click', getImageSrc)
    src = []
  })
  return { src }
}
