<!--
 * @Descripttion:
 * @version: 1.0.0
 * @Author: liujx@imyfone.cn
 * @Date: 2024-06-13 14:52:01
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-13 15:15:32
-->
<template>
  <p class="common-icon view-icon">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M3.72 14.62a23.473 23.473 0 0 1-.486-.62 25.191 25.191 0 0 1 2.958-3.264C8.31 8.786 11.072 7 14 7s5.69 1.785 7.808 3.736A25.194 25.194 0 0 1 24.766 14a25.194 25.194 0 0 1-2.958 3.264C19.69 19.214 16.928 21 14 21s-5.69-1.785-7.808-3.736a25.191 25.191 0 0 1-2.472-2.643z" stroke="#999" stroke-width="2"/><circle cx="14" cy="14" r="3" stroke="#999" stroke-width="2"/></svg>
    <span>{{ count }}</span>
 </p>
</template>

<script setup>
import { defineProps, computed } from 'vue'
const props = defineProps({
  count: {
    type: [String, Number],
    default: 0
  }
})
const count = computed(() => props.count === 0 ? 1 : props.count)

</script>

<style scoped lang="less">
@import '../../assets/less/icon.less';
.view-icon:hover svg:hover circle{
  stroke: #7972f0;
}
</style>
