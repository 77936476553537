<template>
  <div
    class="artical-this"
    v-infinite-scroll="load"
    infinite-scroll-immediate="false"
    infinite-scroll-distance="0"
  >
    <!-- 帖子内容 -->
    <div class="artical-detail">
      <GoBack />
      <el-card class="card-cont">
        <div class="bread">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="{to, name} in filterCrumbList" :to="to" :key="name"
              >{{name}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <!-- 文章详情 -->
        <div
          :class="[
            'texts',
            artDetail ? 'autoHeight' : '',
            showAll ? 'maxHeight' : ''
          ]"
          id="artDetailRef"
        >
          <template v-if="useOldEditor(artDetail.created_at)">
              <div class="artical-title">
                <h4 v-html=" formatHtml(artDetail.title)"></h4>
              </div>
              <div
                class="art-content content editor-content"
                v-html="artDetail.content"
              ></div>
          </template>
          <!-- md编辑器  -->
          <template v-else>
            <div class="art-content content editor-content">
              <mavon-editor
                class="md"
                v-model="artDetail.content"
                :subfield="false"
                :defaultOpen="'preview'"
                :toolbarsFlag="false"
                :editable="false"
                :scrollStyle="true"
                :ishljs="true"
                />
            </div>
          </template>
        </div>
        <template v-if="height > 1980">
          <div class="forum-showmore" @click="showMoreCon">
            <el-button type="info"
              >{{ showAll ? '展开' : '收起'
              }}<el-icon v-if="!showAll"><arrow-up-bold /></el-icon
              ><el-icon v-else><arrow-down-bold /></el-icon
            ></el-button>
          </div>
        </template>

        <!-- 显示点赞列表 -->
        <LikeInfo v-if="artDetail && artDetail.id" ref="likeRef" :searchId="artDetail.id" :searchType="2" @showLike="showLike"></LikeInfo>

        <div class="detail-user" id="commentArcher">
          <div class="detail-user-top">
            <UserAvatar
              v-if="artDetail"
              :user="artDetail.user"
              size="50"
              fontSize="12"
              medalSize="70"
            ></UserAvatar>
            <div class="artical-user">
              <span
                class="artical-username"
                @click="toUserCenter(artDetail.user.id)"
              >
                {{ artDetail && artDetail.user && artDetail.user.name }}
              </span>
              <div class="artical-user-time">
                {{ artDetail && analyzeEmoji(formatDate(artDetail.created_at)) }} 发布于
                {{
                  artDetail && artDetail.cate_name
                }}
              </div>
            </div>
          </div>
          <!-- 功能按钮 -->
          <div class="operation-icon">
            <template v-if="artDetail && store.state.userInfo.userInfos.id === artDetail.user_id">
              <EditIcon @handleClick="editForum"></EditIcon>
              <DeleteIcon
                :apiMethod="forumDelpost"
                :params="{id: artDetail.id}"
                deleteText="这条帖子"
                @handleClick="delForum"
                ></DeleteIcon>
            </template>
            <ViewIcon :count="artDetail && artDetail.view_count"></ViewIcon>
            <!-- 点赞 -->

            <LikeIconAnimation
              :data="artDetail"
              type="2"
              @handleClick="likeForum(artDetail)"
            >
            </LikeIconAnimation>

            <!-- 收藏 -->
            <CollectIconAnimation
              :data="artDetail"
              type="2"
              @handleClick="collectForum(artDetail)"
            ></CollectIconAnimation>
            <!-- 分享 -->
            <ShareIcon @handleClick="shareForum"></ShareIcon>
          </div>
        </div>
        <!-- 文章评论框组件 -->
        <div class="artical-record">
          <UserAvatar
            :user="store.state.userInfo.userInfos"
            size="38"
            fontSize="12"
            medalSize="58"
          ></UserAvatar>
          <div class="artical-record-content">
            <CommonEditor
              ref="commonEditorRef"
              @submit="pubForumComment"
              buttonText="评论"
              placeholder="留下你的神评论～"
            ></CommonEditor>
          </div>
        </div>

        <div class="article-comment">
        <!--  评论列表 -->
          <CommentList
            ref="commentListRef"
            :id="artDetail.id"
            :userId="artDetail && artDetail.user_id"
            commentType="2"
          >
          </CommentList>
        </div>

      </el-card>

      <el-affix :offset="71">
        <!-- 右侧工具栏 -->
        <div class="right-option">
          <!-- 收藏 -->
          <CollectIconAnimation
            :data="artDetail"
            type="2"
            :isCountShow="false"
            @handleClick="collectForum(artDetail)"
          ></CollectIconAnimation>
          <!-- 分享 -->
          <ShareIcon @handleClick="shareForum()"></ShareIcon>
          <!-- 点赞 -->
          <LikeIconAnimation
            :data="artDetail"
            type="2"
            :isCountShow="false"
            @handleClick="likeForum(artDetail)"
          >
          </LikeIconAnimation>
          <!-- 评论 -->
          <CommentIcon @handleClick="toCommentBox" count="0"></CommentIcon>
        </div>
      </el-affix>
    </div>

    <!-- 分享弹出框 -->
    <el-dialog
      v-model="shareDialogVisible"
      custom-class="share-dialog"
      title="转发到动态"
      :before-close="handleClose"
      :show-close="false"
    >
      <CommonEditor
        :limit="'1200'"
        editorType="forum"
        buttonText="分享"
        placeholder="这个帖子真不错，分享给大家看看吧~"
        @submit="shareContent"
      ></CommonEditor>
    </el-dialog>
  </div>
  <el-image-viewer v-if="imgpreVisible" :url-list="srcList" :initial-index="initIndex" :hide-on-click-modal="true" @close="closeImageviewer"></el-image-viewer>
  <LikeUserDialog v-model="likeDialogVisible" :likeUserList="likeUserList" :diaState="'forum'" normalType="forum"></LikeUserDialog>
</template>

<script setup>
import { nextTick, reactive, ref, toRefs, watch, onMounted, computed } from 'vue'

import mySocket from '@/mixin/socket.js'
import { toUserCenter } from '@/utils/userCenter.js'
import { forumPostInfo, forumDelpost, forumShare } from '@/apis/forum.js'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { myToast, dialogMsg } from '@/utils/dialogMsg.js'
import elementResizeDetectorMaker from 'element-resize-detector'
import imagePre from '@/mixin/imagePreview.js'
import LikeUserDialog from '@/components/blog/LikeUserDialog.vue'
import LikeIconAnimation from '@/components/icon/LikeIconAnimation.vue'
import CollectIconAnimation from '@/components/icon/CollectIconAnimation.vue'
import ShareIcon from '@/components/icon/ShareIcon.vue'
import CommentIcon from '@/components/icon/CommentIcon.vue'
import DeleteIcon from '@/components/icon/DeleteIcon.vue'
import EditIcon from '@/components/icon/EditIcon.vue'
import ViewIcon from '@/components/icon/ViewIcon.vue'
import { feedsFeedComment } from '@/apis/blogs.js'
import { formatDate, analyzeEmoji } from '@/utils/filterHtml.js'
import CommonEditor from '@/components/commonEditor/CommonEditor.vue'
import CommentList from '@/components/comment/CommentList.vue'
import LikeInfo from '@/components/blog/LikeInfo.vue'
import { useOldEditor } from '@/utils/tools'

const route = useRoute()
const router = useRouter()
const store = useStore()
// 保存帖子信息

const state = reactive({
  artDetail: {},
  likeRef: null,
  srcList: [],
  initIndex: computed({
    get: () => {
      return state.srcList.findIndex(i => i === store.state.forum.previewSrc)
    }
  }),
  likeUserList: [],
  likeDialogVisible: false,
  crumbList: [
    {
      name: '论坛',
      to: { path: '/forum' },
      isShow: true
    },
    {
      name: computed(() => state.artDetail.cate_name),
      to: {
        path: '/forum',
        query: { cateId: computed(() => state.artDetail.cate_id) }
      },
      isShow: true
    },
    {
      name: computed(() => state.artDetail.tag_name),
      to: {
        path: '/forum',
        query: {
          cateId: computed(() => state.artDetail.cate_id),
          tagId: computed(() => state.artDetail.tag_id)
        }
      },
      isShow: computed(() => !!state.artDetail.tag_id)
    },
    {
      name: '帖子详情',
      to: null,
      isShow: true
    }
  ],
  commentListRef: null,
  commonEditorRef: null,
  shareDialogVisible: false
})

const imgpreVisible = computed(() => store.state.imagePreVisible)
const filterCrumbList = computed(() => state.crumbList.filter(i => i.isShow))

const { artDetail, likeRef, srcList, initIndex, likeUserList, likeDialogVisible, commentListRef, commonEditorRef, shareDialogVisible } = toRefs(state)
// 触底加载
function load () {
  if (!store.state.noMore && !store.state.loadMore) {
    store.commit('setLoadMore', true)
    state.commentListRef.page++
    getCommentList()
  }
}
// 获取帖子详情
function getForum (id) {
  state.artDetail.id = Number(id)
  forumPostInfo(id)
    .then((res) => {
      if (res.code === 0) {
        state.artDetail = res.data
        isInit.value = true
        const pattern = /<img.*?(?:>|\/>)/gi
        const srcReg = /\s+src=['"]?([^'"]*)['"]?/i
        const arr = res.data.content.match(pattern)
        const temp = []
            arr?.length && arr.forEach(i => {
          temp.push(i.match(srcReg)[0].split('=')[1].split(['"'])[1])
        })
            state.srcList = temp
      }
      getCommentList()
    })
    .catch((err) => {
      console.log(err)
    })
}

// 删除帖子
function delForum () {
  router.push({ path: '/forum' })
}

// 点赞帖子
function likeForum (data) {
  getForum(data.id)
  // 更新点赞
  state.likeRef.init().then(res => {
    state.artDetail.like_count = res
  })
}
// 收藏
function collectForum (data) {
  if (data.is_collection === 1) {
    // 收藏
    state.artDetail.is_collection = 2
    state.artDetail.collect_count += 1
  } else {
    state.artDetail.is_collection = 1
    state.artDetail.collect_count -= 1
  }
}

const handleClose = (done) => {
  done()
}
function shareForum () {
  state.shareDialogVisible = true
}
// 调用分享到微博的请求
function shareContent (data, callback) {
  forumShare({
    content:
          (state.artDetail && state.artDetail.title) +
          '</br></br>' +
          data.feed_content,
    post_id: state.artDetail.id,
    at_user: data.at_user,
    image_url: data.images_url
  })
    .then((res) => {
      if (res.code === 0) {
        // 微博小红点
        sendToSocket(undefined, { type: 1, feedID: res.data.feed_id })
        // @ta人
        sendToSocket(data.at_user, { type: 4 })
        myToast({ type: 'success', message: '分享成功' })
      } else {
        dialogMsg('error', res.msg)
      }
      callback(res.code)
      state.shareDialogVisible = false
    })
    .catch((err) => {
      callback()
      state.shareDialogVisible = false
      console.log(err)
    })
}
const { sendToSocket } = mySocket()

// 发布评论
function pubForumComment (data) {
  // 重置触底加载
  store.commit('setLoadMore', false)
  store.commit('setNoMore', false)

  const params = {
    id: state.artDetail.id,
    commentable_type: 2,
    body: data.feed_content,
    target_user: state.artDetail.user.id,
    at_user: data.at_user,
    images_url: data.images_url
  }
  feedsFeedComment(params)
    .then((res) => {
      if (res.code === 0) {
        nextTick(() => {
          myToast({ type: 'success', message: '评论成功' })
          const socketArr = [state.artDetail.user.id]
          sendToSocket(socketArr, { type: 5 })
          sendToSocket(data.at_user, { type: 4 })
          getCommentList()
          state.commonEditorRef.resetEditorInfo()
        })
      } else {
        dialogMsg('error', res.msg)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

function getCommentList () {
  nextTick(() => {
    state.commentListRef.getCommentList()
    const noMore = computed(() => state.commentListRef?.commentCount === state.commentListRef?.commentList.length)
    store.commit('setNoMore', noMore.value) // 无数据
  })
}

// 监听id变化
watch(
  () => route.params.id,
  (id) => {
    if (id) {
      state.forumId = id
      getForum(id)
    }
  }
)
const showAll = ref(false)
const height = ref(0)
const isInit = ref(false)
onMounted(() => {
  getForum(route.params.id)
  const erd = elementResizeDetectorMaker()
  const dom = document.querySelector('#artDetailRef')
  if (dom) {
    erd.listenTo(dom, (element) => {
      if (isInit.value) {
        // console.log(height.value)
        height.value = document.querySelector('.art-content').offsetHeight
        showAll.value = height.value > 1980
        isInit.value = false
      }
    })
  }
})
function showMoreCon () {
  showAll.value = !showAll.value
  showAll.value && (document.scrollingElement.scrollTop = 0)
}
function toCommentBox () {
  document.querySelector('#commentArcher') &&
  document.querySelector('#commentArcher').scrollIntoView()
}

// 编辑帖子
const editForum = () => {
  // 跳转帖子编辑页面
  router.push({
    name: 'PublishForum',
    params: {
      cate_id: state.artDetail.cate_id,
      tag_id: state.artDetail.tag_id,
      articalId: state.artDetail.id,
      content: state.artDetail.content,
      title: state.artDetail.title
    }
  })
}

watch(() => state.imgpreVisible, (newValue) => {
  newValue ? document.documentElement.style.overflowY = 'hidden' : document.documentElement.style.overflowY = 'auto'
})

// 监听点击图片
imagePre()
const closeImageviewer = () => {
  store.commit('setImagePreVisible', false)
}

// 统一处理点赞弹窗
const showLike = ({ userList }) => {
  state.likeUserList = userList
  state.likeDialogVisible = true
}

</script>

<style lang="less" scoped>
@import "../../../../assets/less/ueditorContent.less";
.artical-this {
  width: 932px;
  margin: 0 auto;
  :deep(.el-card__body) {
    padding: 0;
  }

  .artical-detail {
    display: flex;
    position: relative;
    .card-cont {
      width: 932px;
    }
    width: 1026px;
    margin: 0 auto;

    .bread {
      padding: 15px 50px 15px 96px;
      border-bottom: 1px solid #f1f1f1;
      :deep(.el-breadcrumb__inner a),
      :deep(.el-breadcrumb__inner.is-link),
      :deep(.el-breadcrumb__inner) {
        font-size: 14px;
        font-weight: normal;
        color: @second-text-color !important;
      }
    }

    .article-comment {
      padding: 0 47px;
      margin-top: 16px;
    }

    .artical-title {
      color: @second-text-color;
    }
    .texts {
      padding: 27px 48px;
      height: 0;
      max-height: unset;
      &.autoHeight {
        height: auto;
      }
      &.maxHeight {
        max-height: 1980px;
        overflow: hidden;
      }

      h4 {
        font-size: 20px;
        color: @default-text-color;
        margin-bottom: 13px;
      }
    }
    .detail-user {
      padding: 15px 47px;
      padding-bottom: 24px;
      display: flex;
      justify-content: space-between;
      .detail-user-top{
        display: flex;
        align-items: center;
      }
      .artical-user {
        display: inline-block;
        height: 50px;
        color: @default-text-color;
        margin-left: 6px;
        .artical-username {
          font-size: 14px;
          cursor: pointer;
        }
        .artical-user-time {
          margin-top: 8px;
          font-size: 12px;
          color: @second-text-color;
        }
      }
      .operation-icon {
        color: @second-text-color;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 12px;
        flex: 1;
        p {
          margin-left: 16px;
          display: flex;
          align-items: center;
          cursor: pointer;
      }
    }
    }
    .artical-record {
      display: flex;
      border-top: 1px solid #f2f2f2;
      border-bottom: 1px solid #f2f2f2;
      padding: 32px 47px 16px 47px;
      .user-avatar {
        margin-right: 9px;
        transform: translateY(10px);
        align-items: flex-start;
      }
      :deep(.arena){
        height: 120px;
      }
      .artical-record-content {
        width: 100%;
        .emoji-picker {
          top: -190px;
        }
        .textarea {
          background: @colorWhite;
        }
      }
    }

    i {
      font-style: normal;
      cursor: pointer;
    }

    .forum-showmore {
      padding: 33px 47px;
      .el-button {
        padding: 0;
        font-size: 14px;
        height: 32px;
        min-height: 32px;
        width: 100%;
        background: #f6f9fa;
        color: @default-text-color;
        border: 1px solid #f1f1f1;
        border-radius: 6px;
        i {
          vertical-align: top;
          margin-left: 8px;
        }
      }
    }

  // 点赞列表
  :deep(.like-list) {
    margin-top: -7px;
    padding: 0 47px !important;
    margin-bottom: 9px;
  }

  .right-option {
    margin-left: 8px;
    background: #fff;
    width: 86px;
    padding: 23px 24px 16px 24px;
    font-size: 12px;
    color: @other-text-color;
    border: 1px solid #f1f1f1;
    border-radius: 6px;
    :deep(.common-icon){
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  }
}

:deep(.artical-detail .wrapper .emoji-picker) {
  top: -190px;
}

:deep(.artical-detail .back) {
  height: auto;
  border: none;
  line-height: 1;
  position: absolute;
  top: 15px;
  left: 20px;
  .go-back {
    width: auto;
  }
  .go-back .header-svg,
  .go-back .header-svg-hover {
    margin: 0 8px 0 0;
  }
}
</style>

<style lang="less">
.like-dialog {
  height: 456px !important;
  border-radius: 6px;
  overflow: hidden;
  .el-dialog__header {
    height: 45px;
    line-height: 45px;
    padding: 0;
    padding-left: 20px;
    border: 1px solid #F1F1F1;
    color: @active-text-color;
    & span {
      display: inline-block;
      width: 55px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      border-bottom: 3px solid @active-text-color;
      transform: translateY(-3px);
      border-radius: 2px;
    }
  }
  .el-dialog__body {
    height: 411px;
    padding: 32px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 5px;
        height: 6px;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb {
        background: #e0e0e0;
        border-radius: 6px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: #a8a8a8;
    }
    .user-wrapper {
      height: 74px;
      text-align: center;
      width: 40px;
      margin-right: 29px;
      .user-avatar {
        margin-right: 0;
      }
      .user-name {
        font-size: 12px;
        line-height: 16px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:nth-child(8n) {
        margin-right: 0;
      }
    }
  }
}
.artical-this .comment-list .comment-left .user-medal:before {
  transform: translateY(5%) !important;
}

.share-dialog {
  width: 720px !important;
  .el-dialog__body {
    padding: 20px;
  }
}
</style>
